/**
 * Invoices List
 */
.coach-invoices > h3 {
    margin-bottom: 8px;
}
.coach-invoices > ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.coach-invoices > ul > li {
    display: flex;
    justify-content: space-between;
}
.coach-invoices > div {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid var(--lighter-color);
    text-align: right;
}
