/**
 * The Create List
 */
.creating-list.creating-list {
    margin-top: 8px;
}
.creating-list li {
    position: relative;
    display: flex;
    padding-bottom: 16px;
}
.creating-list li:before {
    content: "";
    position: absolute;
    top: 0;
    left: 22px;
    bottom: 0;
    border-left: 1px solid rgb(209, 213, 218);
}
.creating-list li:last-child {
    padding-bottom: 0;
}


.creating-step {
    flex-shrink: 0;
    position: relative;
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background-color: var(--light-gray);
    border: 2px solid white;
    z-index: 1;
}

.creating-content {
    margin-top: 8px;
    margin-left: 16px;
    opacity: 0.4;
}
.creating-content h3 {
    margin: 0 0 4px 0;
    color: var(--title-color);
    font-family: var(--title-font);
    font-size: 18px;
}
.creating-content p {
    margin: 0;
}
.creating-content .btn {
    margin-top: 8px;
}


.creating-active .creating-step {
    color: white;
    background-color: var(--primary-color);
}
.creating-active .creating-content {
    opacity: 1;
}

.creating-completed .creating-step {
    color: white;
    background-color: var(--success-color);
}
