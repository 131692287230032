/**
 * The Home
 */
.home {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 360px;
    grid-column-gap: 32px;
    margin-bottom: 32px;
    padding-bottom: 0;
}
.home h3 {
    margin: 0;
    color: var(--font-light);
}

.home-content {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--full-height) - var(--header-height) - 32px);
}

.home-complete {
    display: block;
    padding: 6px 16px;
    margin-bottom: 16px;
    border-radius: var(--border-radius);
    background-color: var(--warning-color);
}

.home-news {
    position: relative;
    padding: 18px 12px 12px 12px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    margin-top: 8px;
    margin-bottom: 24px;
}
.home-news h4 {
    position: absolute;
    top: -10px;
    left: 10px;
    margin: 0;
    padding: 0 4px;
    background-color: white;
}
.home-news header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home-news h3 {
    margin-bottom: 8px;
    font-size: 18px;
    font-family: var(--title-font);
}


.home-title {
    margin: 24px 0 8px 0;
    font-size: 20px;
    font-family: var(--title-font);
}
.home-activities {
    flex-grow: 8;
}
.home-activity.home-activity {
    padding: 8px 16px;
    margin: 8px 0;
    background-color: var(--lighter-gray);
    border-radius: var(--border-radius);
    color: var(--black-color);
    line-height: 1.4;
}
.home-activity .link-content {
    display: flex;
}
.home-activity i {
    flex-shrink: 0;
    display: block;
    width: 80px;
    color: var(--title-color);
    margin-right: 12px;
}


.home-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 16px;
    padding: 8px 0 24px 0;
}
.home-grid > div {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    transition: all 0.2s;
}

.home-advice {
    border-radius: var(--border-radius);
    overflow: hidden;
}
.home-advice img {
    display: block;
    width: 100%;
}



/**
 * Media Queries
 */
@media screen and (max-width: 1000px) {
    .home-content {
        min-height: 0px;
    }
}

@media screen and (max-width: 900px) {
    .home {
        display: block;
    }
    .home-advice {
        display: none;
    }
}
