/**
 * The Ticket Messages
 */
.ticket-content h4 {
    margin: 0;
    padding: 12px 24px;
}

.ticket-message {
    position: relative;
    display: flex;
    padding: 16px 24px;
    color: var(--black-color);
}
.ticket-message:not(:last-child) {
    border-bottom: 1px solid var(--light-gray);
}
.ticket-header {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    color: var(--title-color);
}
.ticket-header h3 {
    margin: 0 16px 0 0;
    font-size: 14px;
}
.ticket-header h4 {
    margin: 0;
    padding: 0;
    color: var(--font-dark);
    font-size: 11px;
    font-weight: 400;
}

.ticket-avatar {
    flex-shrink: 0;
    width: 32px;
    border-radius: 50%;
    margin-right: 16px;
}
.ticket-more {
    position: absolute;
    top: 12px;
    right: 16px;
    font-size: 20px;
    width: 24px;
    height: 24px;
    line-height: 24px;
}
.ticket-menu {
    top: 12px;
    right: 16px;
    transform: none;
}

.ticket-image {
    display: block;
    max-width: 100%;
}



/**
 * The Ticket Reply
 */
.ticket-reply {
    display: flex;
    align-items: center;
    padding: 16px 16px 1px 16px;
    background-color: var(--light-gray);
}
.ticket-input {
    flex-grow: 2;
}
.ticket-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    padding: 0;
    margin-left: 8px;
    font-size: 18px;
    border-radius: 50%;
}



/**
 * Media Queries
 */
@media screen and (max-width: 700px) {
    .ticket-info .actions {
        display: flex;
        flex-direction: column;
    }
    .ticket-info .actions .btn + .btn {
        margin-left: 0;
        margin-top: 4px;
    }
}

@media screen and (max-width: 500px) {
    .ticket-reply {
        padding-bottom: 16px;
    }
}
