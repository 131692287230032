/**
 * Enroll Page
 */
.enroll-filter {
    position: sticky;
    top: 0;
    padding: 8px 16px 12px;
    background-color: var(--lighter-gray);
    border-radius: var(--border-radius);
    margin-bottom: 16px;
}
.enroll-filter-main {
    display: grid;
    grid-gap: 8px;
}
.enroll-filter-main-2 {
    grid-template-columns: repeat(2, 1fr);
}
.enroll-filter-main-3 {
    grid-template-columns: repeat(3, 1fr);
}
.enroll-filter-main-4 {
    grid-template-columns: repeat(4, 1fr);
}

.enroll-filter-sec {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    margin-top: 8px;
}



.enroll-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 16px;
    padding: 0 0 16px 0;
}

.enroll-container > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    transition: all 0.2s;
}
.enroll-container > div:hover,
.enroll-selected.enroll-selected {
    border-color: var(--boder-color);
}

.enroll-content h3 {
    margin: 0 0 8px 0;
    color: var(--font-light);
    font-family: var(--title-font);
    font-size: 20px;
}
.enroll-content h4 {
    margin: 0 0 8px 0;
    font-size: 15px;
}
.enroll-content p {
    margin: 0 0 8px 0;
}
.enroll-location.enroll-location {
    margin-bottom: 16px;
}

.enroll-footer {
    display: flex;
    flex-direction: column;
    margin: 8px 0 0 0;
}
.enroll-footer .btn.btn.btn {
    margin-bottom: 8px;
    margin-left: 0;
}



/**
 * Media Queries
 */
 @media screen and (max-width: 550px) {
    .enroll-filter-main-4 {
        grid-template-columns: repeat(2, 1fr);
    }
}
