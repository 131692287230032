/**
 * The Questions
 */
.question {
    border: 2px solid var(--light-gray);
    border-radius: var(--border-radius);
    margin-bottom: 16px;
}
.question h3 {
    margin: 0;
    padding: 8px 16px;
    color: var(--font-light);
    font-size: 16px;
    font-weight: 400;
    background-color: var(--light-gray);
}
.question-required {
    margin-left: 4px;
    color: var(--error-color);
    font-weight: bold;
}
.question-radio {
    padding: 8px 8px 16px 8px;
}
.question-text,
.question-textarea {
    padding: 16px;
}

.questions-footer {
    padding: 8px 8px 8px 16px;
    margin-bottom: 40px;
    background-color: var(--lighter-gray);
    border-radius: var(--border-radius);
}
