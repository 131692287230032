/**
 * Setup Home
 */
.setup-content.setup-content {
    grid-gap: 24px;
    border-radius: var(--border-radius);
    background-color: var(--light-gray);
}
.setup-details {
    margin-top: 0 !important;
}



/**
 * Media Queries
 */
@media (max-width: 500px) {
    .setup-details:not(:last-child) {
        margin-bottom: 24px;
    }
}
