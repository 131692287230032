
.icon-link:before {
  content: "\e965";
}
.icon-document:before {
  content: "\e96d";
}
.icon-origin:before {
  content: "\e964";
}
.icon-whiteemail:before {
  content: "\e96b";
}
.icon-campaign:before {
  content: "\e966";
}
.icon-subscription:before {
  content: "\e96e";
}
.icon-finalize:before {
  content: "\e96a";
}
.icon-postpone:before {
  content: "\e969";
}
.icon-level:before {
  content: "\e968";
}
.icon-access:before {
  content: "\e968";
}
.icon-lead:before {
  content: "\e967";
}
.icon-interest:before {
  content: "\e963";
}
.icon-email:before {
  content: "\e938";
}
.icon-queue:before {
  content: "\e962";
}
.icon-download:before {
  content: "\e961";
}
.icon-groups:before {
  content: "\e95f";
}
.icon-staff:before {
  content: "\e95f";
}
.icon-meeting:before {
  content: "\e960";
}
.icon-assignment:before {
  content: "\e95e";
}
.icon-speciality:before {
  content: "\e95d";
}
.icon-whatsapp:before {
  content: "\e956";
}
.icon-support:before {
  content: "\e95c";
}
.icon-course:before {
  content: "\e95b";
}
.icon-mode:before {
  content: "\e95a";
}
.icon-invoice:before {
  content: "\e959";
}
.icon-province:before {
  content: "\e958";
}
.icon-copy:before {
  content: "\e953";
}
.icon-locality:before {
  content: "\e950";
}
.icon-guide:before {
  content: "\e944";
}
.icon-help:before {
  content: "\e944";
}
.icon-news:before {
  content: "\e942";
}
.icon-advice:before {
  content: "\e93d";
}
.icon-type:before {
  content: "\e921";
}
.icon-address:before {
  content: "\e94d";
}
.icon-location:before {
  content: "\e94d";
}
.icon-country:before {
  content: "\e936";
}
.icon-material:before {
  content: "\e914";
}
.icon-complete:before {
  content: "\e92d";
}
.icon-conversation:before {
  content: "\e903";
}
.icon-ticket:before {
  content: "\e905";
}
.icon-gender:before {
  content: "\e92e";
}
.icon-cancel:before {
  content: "\e92b";
}
.icon-presentism:before {
  content: "\e90d";
}
.icon-attachment:before {
  content: "\e919";
}
.icon-billing:before {
  content: "\e907";
}
.icon-money:before {
  content: "\e907";
}
.icon-calendar:before {
  content: "\e92f";
}
.icon-import:before {
  content: "\e91f";
}
.icon-export:before {
  content: "\e920";
}
.icon-directory:before {
  content: "\e91b";
}
.icon-inscription:before {
  content: "\e90e";
}
.icon-strech:before {
  content: "\e908";
}
.icon-expand:before {
  content: "\e924";
}
.icon-open:before {
  content: "\e924";
}
.icon-up:before {
  content: "\e910";
}
.icon-down:before {
  content: "\e913";
}
.icon-class:before {
  content: "\e90b";
}
.icon-birthdate:before {
  content: "\e91c";
}
.icon-unmute:before {
  content: "\e922";
}
.icon-mute:before {
  content: "\e923";
}
.icon-assign:before {
  content: "\e927";
}
.icon-student:before {
  content: "\e916";
}
.icon-checkedbox:before {
  content: "\efff";
}
.icon-checkbox:before {
  content: "\effe";
}
.icon-check:before {
  content: "\e929";
}
.icon-home:before {
  content: "\e91d";
}
.icon-media:before {
  content: "\e91a";
}
.icon-description:before {
  content: "\e902";
}
.icon-today:before {
  content: "\e930";
}
.icon-language:before {
  content: "\e900";
}
.icon-section:before {
  content: "\e90f";
}
.icon-ocupation:before {
  content: "\e926";
}
.icon-week:before {
  content: "\e931";
}
.icon-report:before {
  content: "\e909";
}
.icon-goto:before {
  content: "\e93c";
}
.icon-details:before {
  content: "\e92a";
}
.icon-arrival:before {
  content: "\e925";
}
.icon-room:before {
  content: "\e91e";
}
.icon-program:before {
  content: "\e90a";
}
.icon-group:before {
  content: "\e906";
}
.icon-status:before {
  content: "\e957";
}
.icon-time:before {
  content: "\e94f";
}
.icon-schedule:before {
  content: "\e94f";
}
.icon-observations:before {
  content: "\e951";
}
.icon-send:before {
  content: "\e952";
}
.icon-contact:before {
  content: "\e954";
}
.icon-phone:before {
  content: "\e955";
}
.icon-cuit:before {
  content: "\e94e";
}
.icon-dni:before {
  content: "\e94e";
}
.icon-taxID:before {
  content: "\e94e";
}
.icon-view:before {
  content: "\e94b";
}
.icon-edit:before {
  content: "\e94c";
}
.icon-category:before {
  content: "\e949";
}
.icon-more:before {
  content: "\e94a";
}
.icon-last:before {
  content: "\e946";
}
.icon-first:before {
  content: "\e947";
}
.icon-prev:before {
  content: "\e948";
}
.icon-filter:before {
  content: "\e945";
}
.icon-setup:before {
  content: "\e943";
}
.icon-actions:before {
  content: "\e940";
}
.icon-settings:before {
  content: "\e941";
}
.icon-admin:before {
  content: "\e93e";
}
.icon-user:before {
  content: "\e93f";
}
.icon-coach:before {
  content: "\e93f";
}
.icon-referred:before {
  content: "\e93f";
}
.icon-back:before {
  content: "\e93b";
}
.icon-logout:before {
  content: "\e93a";
}
.icon-password:before {
  content: "\e939";
}
.icon-price:before {
  content: "\e932";
}
.icon-info:before {
  content: "\e933";
}
.icon-signup:before {
  content: "\e934";
}
.icon-login:before {
  content: "\e935";
}
.icon-menu:before {
  content: "\e92c";
}
.icon-add:before {
  content: "\e928";
}
.icon-create:before {
  content: "\e928";
}
.icon-search:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e912";
}
.icon-next:before {
  content: "\e911";
}
.icon-closed:before {
  content: "\e911";
}
.icon-delete:before {
  content: "\e90c";
}
.icon-image:before {
  content: "\e904";
}
.icon-file:before {
  content: "\f016";
}
.icon-file-text:before {
  content: "\f0f6";
}
.icon-error:before {
  content: "\f188";
}
.icon-file-pdf:before {
  content: "\f1c1";
}
.icon-file-document:before {
  content: "\f1c2";
}
.icon-file-spreadsheet:before {
  content: "\f1c3";
}
.icon-file-presentation:before {
  content: "\f1c4";
}
.icon-file-zip:before {
  content: "\f1c6";
}
.icon-file-audio:before {
  content: "\f1c7";
}
.icon-file-video:before {
  content: "\f1c8";
}
.icon-file-code:before {
  content: "\f1c9";
}
.icon-chat:before {
  content: "\f1d7";
}
.icon-amount:before {
  content: "\f292";
}
.icon-id:before {
  content: "\f292";
}
.icon-question:before {
  content: "\e917";
}
.icon-video:before {
  content: "\e918";
}
.icon-certificate:before {
  content: "\e901";
}
.icon-name:before {
  content: "\e937";
}
