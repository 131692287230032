/**
 * The Guide Dialog
 */
.guide-dialog {
    flex-shrink: 0;
}

.guide-questions {
    padding: 24px;
}
.guide-questions h3 {
    margin-top: 0;
}
.guide-description {
    color: var(--black-color);
    margin-bottom: 24px;;
}
.guide-description p {
    margin: 0 0 12px 0;
}
.guide-info {
    margin: 0 0 24px 0;
    font-style: italic;
    color: var(--black-color);
}

.guide-help {
    margin-top: 0;
}
.guide-answer {
    text-align: right;
}
.guide-answer .inputfield {
    margin-bottom: 0;
    text-align: left;
}
.guide-answer .inputfield-cnt .input {
    border-bottom-right-radius: 0;
}
.guide-answer .btn {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.guide-answers h3 {
    margin-top: 0;
}
.guide-feedback {
    width: 100%;
    margin-top: 32px;
}
.guide-feedback h3 {
    margin-bottom: 8px;
}



/**
 * The Students List
 */
.students-list.students-list {
    padding: 24px;
}
.students-list li {
    display: flex;
    color: var(--black-color);
}
.students-name {
    flex-grow: 3;
}
.students-text {
    flex-grow: 1;
}
.students-input {
    width: 120px;
    margin-right: 16px;
}
.students-list li:not(:last-child) {
    margin-bottom: 16px;
}
