/**
 * The Welcome
 */
.welcome {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 360px;
    grid-column-gap: 32px;
    margin-bottom: 32px;
    padding-bottom: 0;
}
.welcome h3 {
    margin: 0;
    color: var(--font-light);
}

.welcome-text {
    margin: 8px 0 24px 0;
    font-size: 18px;
}
.welcome-content {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--full-height) - var(--header-height) - 32px);
}

.welcome-news {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    margin-bottom: 24px;
}
.welcome-news header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.welcome-news h3 {
    margin-bottom: 8px;
    font-size: 18px;
    font-family: var(--title-font);
}

.welcome-info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px;
    font-size: 16px;
    background-color: var(--light-gray);
    cursor: pointer;
}
.welcome-info:hover {
    background-color: var(--dark-gray);
}
.welcome-info .icon {
    font-size: 32px;
    margin-right: 16px;
}
.welcome-info p {
    margin: 0;
}

.welcome-advice {
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    overflow: hidden;
}
.welcome-advice img {
    display: block;
    width: 100%;
}



/**
 * Media Queries
 */
@media screen and (max-width: 1000px) {
    .welcome-content {
        min-height: 0px;
    }
}

@media screen and (max-width: 900px) {
    .welcome {
        display: block;
    }
    .welcome-advice {
        display: none;
    }
}
